import React from "react";
import { Title, Box, Container } from "@bookingcom/bui-react";

export type HeroBannerProps = {
	subtitle: string;
	title: string;
};

export const HeroBanner: React.FC<HeroBannerProps> = ({ title, subtitle }) => {
	return (
		<Box
			backgroundColor="brand_primary"
			mixin={{
				padding: 0,
			}}
		>
			<Container>
				<Box
					backgroundColor="brand_primary"
					mixin={{
						paddingBlockStart: 16,
						paddingBlockEnd: 28,
						paddingInlineStart: 0,
					}}
				>
					<Title
						title={title}
						subtitle={subtitle}
						titleTagName="h1"
						subtitleTagName="h2"
						variant="display_3"
						color="inherit"
					/>
				</Box>
			</Container>
		</Box>
	);
};
