import { format } from "date-fns";
import { LocaleObject } from "./get-date-fns-locale";

function formatDayWithMonth(dateToFormat: Date, locale?: LocaleObject): string {
	return format(dateToFormat, "eee, d MMM", locale);
}

function formatTime(dateToFormat: Date, locale?: LocaleObject): string {
	return format(dateToFormat, "HH:mm", locale);
}

function formatDayWithMonthAndTime(dateToFormat: Date, locale?: LocaleObject): string {
	return format(dateToFormat, "eee, d MMM, HH:mm", locale);
}

function formatYearMonthDay(dateToFormat: Date, locale?: LocaleObject): string {
	return format(dateToFormat, "yyyy-MM-dd", locale);
}

export { formatDayWithMonth, formatTime, formatDayWithMonthAndTime, formatYearMonthDay };
