import { differenceInHours } from "date-fns";

const operatorMapping = {
	">": (difference, duration) => {
		return difference > duration;
	},
	"<=": (difference, duration) => {
		return difference <= duration;
	},
};

const isDateWithinWindow = (date: string, operator: string, duration: number): boolean => {
	if (!operatorMapping[operator]) {
		return false;
	}

	return operatorMapping[operator](differenceInHours(new Date(date), new Date()), duration);
};

export { isDateWithinWindow };
