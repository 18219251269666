/* eslint-disable @typescript-eslint/naming-convention */
/** @type {import('./types.ts').ConfigEnvironmentVariables} */
const {
	BOOKING_SSO_CLIENT_ID,
	BOOKING_SSO_CLIENT_SECRET,
	COOKIE_IV,
	COOKIE_SECRET,
	ENCRYPTION_INITIAL_VALUE,
	ENCRYPTION_PASSPHRASE,
	ENVIRONMENT = "local",
	IS_TEST_RUN = "false",
	NODE_CONFIG__BASE_URL = "http://localhost:8080",
	NODE_CONFIG__BOOKING_SSO_SIGN_IN_REDIRECT_URI = "https://book-orchestrator-local.dev.booking.com/auth/callback",
	NODE_CONFIG__DISCOUNT_SERVICE_API_KEY,
	NODE_CONFIG__DISCOUNT_SERVICE_URL = "https://rides-discount-service.service.dev.traveljigsaw.com",
	NODE_CONFIG__DYNAMIC_CONFIG_BASE_URL = "http://localhost:2772",
	NODE_CONFIG__DYNAMIC_CONFIG_DEPLOYMENT_URI,
	NODE_CONFIG__ENABLE_EXPERIMENT_TOOL_FT = "false",
	NODE_CONFIG__ET_DAEMON_HOSTNAME = "daemon",
	NODE_CONFIG__FLIGHT_PICKER_URL = "https://mfeflightpicker.qa.taxi.booking.com/",
	NODE_CONFIG__JAEGER_COLLECTOR = "http://localhost:14268/api/traces",
	NODE_CONFIG__LOCALISATION_API = "http://localisation.eu-central-1.dev.bookinggo.cloud",
	NODE_CONFIG__MOCK_BOOKING_META = "false",
	NODE_CONFIG__OPEN_API_DEV_INTERSTITIAL = "true",
	NODE_CONFIG__OPEN_API_ENABLED = "true",
	NODE_CONFIG__OPEN_API_SILENT_MODE = "false",
	NODE_CONFIG__ORCA_BASE_URL = "https://accommodations.dqs.booking.com/",
	NODE_CONFIG__REDIS_HOST,
	NODE_CONFIG__REDIS_PASSWORD,
	NODE_CONFIG__REDIS_TLS,
	NODE_CONFIG__REDIS_USERNAME,
	NODE_CONFIG__SEARCH_FORM_URL = "https://searchform.qa.taxi.booking.com/taxi-search-form/",
	NODE_CONFIG__SEARCH_FORM_MFS_URL = "https://search-form-mfe.taxi-web-web-test.booking-tech.net/search-form-mfe",
	NODE_CONFIG__SEARCH_RESULTS_URL = "https://search-results-mfe.taxi-web-web-test.booking-tech.net",
	NODE_CONFIG__SERVICES_URL = "https://services-api.qa.someonedrive.me",
	NODE_CONFIG__SESSION_COOKIE_DOMAIN = "dev.booking.com",
	NODE_CONFIG__SESSION_COOKIE_NAME = "core.connect.sid",
	NODE_CONFIG__SESSION_SECRET = "super-secret-local-session-secret",
	NODE_CONFIG__SESSION_TRACKING_URL = "https://0nzgyjapu3.execute-api.eu-west-1.amazonaws.com/qa/api/v1/sessions",
	NODE_CONFIG__SERVICE_PERSONA = "prebook_taxi_core_experience_book",
	NODE_CONFIG__STATIC_URL = "http://localhost:8080/static-assets/cba-template-service-name",
	NODE_CONFIG__TAXI_AUTOCOMPLETE_PROXY_BASE_URL = "https://taxi-autocomplete-lambda.taxi-web-web-test.booking-tech.net",
	NODE_CONFIG__TRACKING_URL = "https://tracking-mfe.taxi-web-web-dev.booking-tech.net",
	NODE_CONFIG__TRIPS_API_URL = "https://trips-api.dqs.booking.com",
	NODE_CONFIG__WEB_SHELL_HEADER_URL = "https://accommodations.dqs.booking.com/orca",
} = process.env;
/* eslint-enable @typescript-eslint/naming-convention */

const COMPONENT_NAME = "book-orchestrator";

const isLocal = ENVIRONMENT === "local" || ENVIRONMENT === "docker-local";
const isTestRun = IS_TEST_RUN === "true";

const config = /** @type {const} */ {
	appServer: {
		mockBookingMetaHeaders: ENVIRONMENT === "local",
	},
	baseUrl: NODE_CONFIG__BASE_URL,
	bkngCrypto: {
		cookieIv: COOKIE_IV,
		cookieSecret: COOKIE_SECRET,
	},
	componentName: COMPONENT_NAME,
	discountApi: {
		apiKey: NODE_CONFIG__DISCOUNT_SERVICE_API_KEY,
		baseUrl: NODE_CONFIG__DISCOUNT_SERVICE_URL,
	},
	dynamicConfig: {
		baseUrl: NODE_CONFIG__DYNAMIC_CONFIG_BASE_URL,
		defaults: require("./dynamic-config"),
		deploymentUri: NODE_CONFIG__DYNAMIC_CONFIG_DEPLOYMENT_URI,
	},
	enableExperimentToolFT: NODE_CONFIG__ENABLE_EXPERIMENT_TOOL_FT === "true",
	encryptionInitialValue: ENCRYPTION_INITIAL_VALUE,
	encryptionPassphrase: ENCRYPTION_PASSPHRASE,
	environment: ENVIRONMENT,
	errorOnIncorrectTranslationKeys: true,
	etDaemonSidecarHostname: NODE_CONFIG__ET_DAEMON_HOSTNAME,
	flightPickerUrl: NODE_CONFIG__FLIGHT_PICKER_URL,
	gaAccountId: ENVIRONMENT === "prod" ? "UA-63397281-1" : "UA-63397281-5",
	gtTranslations: {
		components: [
			"comments",
			"child-car-seat",
			"flight-picker",
			"policies",
			"action-bar",
			"route-summary",
			"error",
			"free-taxi",
			"price-breakdown",
			"how-it-works",
		],
	},
	jaeger: {
		reporter: {
			collectorEndpoint: isTestRun ? undefined : NODE_CONFIG__JAEGER_COLLECTOR,
			logSpans: isLocal && !isTestRun,
		},
		sampler: {
			param: isLocal ? 1 : 0.1,
			type: "probabilistic",
		},
		serviceName: `rides-${COMPONENT_NAME}`,
	},
	localisationServiceTimeout: 2_000,
	localisationServiceUrl: NODE_CONFIG__LOCALISATION_API,
	mockBookingMetaHeaders: NODE_CONFIG__MOCK_BOOKING_META === "true",
	oneTrust: {
		domainUuid: "8bc8c3bc-954e-473a-a3a8-3257e8e5d4fb",
	},
	openApiValidation: {
		enabled: NODE_CONFIG__OPEN_API_ENABLED === "true",
		devInterstitial: NODE_CONFIG__OPEN_API_DEV_INTERSTITIAL === "true",
		silentMode: NODE_CONFIG__OPEN_API_SILENT_MODE === "true",
	},
	orcaBaseUrl: NODE_CONFIG__ORCA_BASE_URL,
	redis: {
		host: NODE_CONFIG__REDIS_HOST,
		password: NODE_CONFIG__REDIS_PASSWORD,
		secure: (NODE_CONFIG__REDIS_TLS || "true") === "true",
		username: NODE_CONFIG__REDIS_USERNAME,
	},
	searchFormMfsUrl: NODE_CONFIG__SEARCH_FORM_MFS_URL,
	searchFormUrl: NODE_CONFIG__SEARCH_FORM_URL,
	searchResultsUrl: NODE_CONFIG__SEARCH_RESULTS_URL,
	servicePersona: NODE_CONFIG__SERVICE_PERSONA,
	servicesUrl: NODE_CONFIG__SERVICES_URL,
	session: {
		cookieDomain: NODE_CONFIG__SESSION_COOKIE_DOMAIN,
		cookieName: NODE_CONFIG__SESSION_COOKIE_NAME,
		secret: NODE_CONFIG__SESSION_SECRET,
	},
	sessionTrackingUrl: NODE_CONFIG__SESSION_TRACKING_URL,
	sso: {
		allowedRoutes: ["/search", "/search/", "/"],
		clientId: BOOKING_SSO_CLIENT_ID,
		clientSecret: BOOKING_SSO_CLIENT_SECRET,
		scope: "booking_default",
		signInRedirectUri: NODE_CONFIG__BOOKING_SSO_SIGN_IN_REDIRECT_URI,
	},
	staticUrl: NODE_CONFIG__STATIC_URL,
	taxiAutocompleteProxy: {
		baseUrl: NODE_CONFIG__TAXI_AUTOCOMPLETE_PROXY_BASE_URL,
	},
	trackingMfeTimeout: 2_000,
	trackingMfeUrl: NODE_CONFIG__TRACKING_URL,
	tripsApiUrl: NODE_CONFIG__TRIPS_API_URL,
	waf: {
		aclBaseUrl: "https://d8c14d4960ca.d24e0790.eu-central-1.token.awswaf.com/d8c14d4960ca/2d3f6aa41126",
	},
	webShellHeaderUrl: NODE_CONFIG__WEB_SHELL_HEADER_URL,
};

module.exports = config;
