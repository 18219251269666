import React from "react";
import { ListItem, Text, Stack, Image, Title, Container, Box, useViewport, ImageProps } from "@bookingcom/bui-react";
import { LandingPageRowsProps } from "./types";

export const LandingPageRows: React.FC<LandingPageRowsProps> = (props) => {
	const { title, listContent, direction, justifyContent, wrap, background } = props;

	const { isLarge } = useViewport();

	return (
		<Box
			backgroundColor={background}
			mixin={{
				padding: 0,
				paddingBlockStart: 10,
				paddingBlockEnd: 16,
			}}
		>
			<Container>
				{title && (
					<Box
						mixin={{
							padding: 0,
							paddingBlockEnd: 6,
						}}
					>
						<Title title={title} variant="headline_2" />
					</Box>
				)}
				<Stack
					gap={isLarge ? 4 : 8}
					wrap={wrap}
					direction={isLarge ? direction : "column"}
					justifyContent={justifyContent}
					alignItems="start"
				>
					{listContent.map((content) => (
						<Stack.Item key={content.assetName} grow>
							<ListItem
								spacing="large"
								key={content.assetName}
								verticalAlignment="start"
								icon={
									<Image
										asset={
											{
												setName: content.setName,
												assetName: content.assetName,
											} as ImageProps["asset"]
										}
										width="80px"
										height="80px"
										alt={content.assetName}
										contentMode="fit"
									/>
								}
							>
								<Title
									attributes={{ "data-test": `taxi-info-${content.title}` }}
									variant="headline_3"
									title={content.title}
								/>
								<Text variant="body_2">{content.description}</Text>
							</ListItem>
						</Stack.Item>
					))}
				</Stack>
			</Container>
		</Box>
	);
};
