import type { BookingDetailsExpectedParameters } from "../pages/BookingDetailsPage/BookingDetailsPage.types";
import { removeTrailingSlash } from "./remove-trailing-slash";

const deleteSearchParam = (paramName: keyof BookingDetailsExpectedParameters): void => {
	const currentSearchParams = new URLSearchParams(window.location.search);
	currentSearchParams.delete(paramName);

	window.history.replaceState(
		undefined,
		"",
		`${removeTrailingSlash(window.location.pathname)}/?${currentSearchParams.toString()}`,
	);
};

export { deleteSearchParam };
