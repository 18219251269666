import type { BookingDetailsExpectedParameters } from "../pages/BookingDetailsPage/BookingDetailsPage.types";
import { removeTrailingSlash } from "./remove-trailing-slash";

const updateSearchParam = (paramName: keyof BookingDetailsExpectedParameters, paramValue: string): void => {
	const currentSearchParams = new URLSearchParams(window.location.search);
	currentSearchParams.set(paramName, paramValue);

	window.history.replaceState(
		undefined,
		"",
		`${removeTrailingSlash(window.location.pathname)}/?${currentSearchParams.toString()}`,
	);
};

export { updateSearchParam };
