export const COMMENT_METADATA_FIELD_NAMES = {
	"baby-seat": "Baby seats",
	"booster-seat": "Booster seats",
	"child-car-seat": "Child car seats",
	"generic-child-seat": "child seats requested",
	"traveller-comments": "Passenger comments",
} as const;

export const COMMENT_METADATA_SEPARATOR = "///";
export const COMMENT_METADATA_SEPARATOR_ESCAPED = "-//-";
export const COMMENT_METADATA_PREFIX = "Preselected extras: ";
