import React from "react";
import { Title, Container, Box, Carousel, Banner, Text, ImageProps } from "@bookingcom/bui-react";
import { getImageAssetUrl } from "@bookingcom/bui-assets-react";
import { SelectByValueType } from "../../types/utilities";

export type TransportImagesAssets = SelectByValueType<
	ImageProps["asset"],
	{
		assetName: unknown;
		setName: "images-transport";
	}
>;

export interface TaxiItem {
	alt: string;
	assetName: TransportImagesAssets["assetName"];
	passengers: string;
	suitcases: string;
	title: string;
}

export interface TaxiProps {
	taxis: TaxiItem[];
	title: string;
}

export const AirportTaxiCarousel: React.FC<TaxiProps> = ({ taxis, title }) => {
	return (
		<Box
			mixin={{
				padding: 0,
				paddingBlockEnd: 16,
			}}
		>
			<Container>
				<Box
					mixin={{
						padding: 0,
						paddingBlockEnd: 4,
					}}
				>
					<Title title={title} variant="headline_2" />
				</Box>

				<Carousel
					size="larger"
					nextButtonAriaLabel="Next content"
					previousButtonAriaLabel="Previous content"
					action={{
						type: "button",
					}}
				>
					{taxis.map((taxi) => (
						<Carousel.Item key={taxi.assetName}>
							<Banner
								startImage={{
									src: getImageAssetUrl({
										setName: "images-transport",
										assetName: taxi.assetName,
									}),
									alt: taxi.alt,
								}}
								dismissible={false}
								className="background-alt"
							>
								<Title title={taxi.title} />
								<Text>{taxi.passengers}</Text>
								<Text>{taxi.suitcases}</Text>
							</Banner>
						</Carousel.Item>
					))}
				</Carousel>
			</Container>
		</Box>
	);
};
