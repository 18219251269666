/* eslint sort-keys: ["error", "asc", { natural: true }] */

/* eslint-disable @typescript-eslint/naming-convention */
const {
	DYNAMIC_CONFIG__ENABLE_LANDING_PAGE = "true",
	DYNAMIC_CONFIG__ENABLE_LANDING_PAGE_GENIUS_BANNER = "true",
	DYNAMIC_CONFIG__TEST_NUMBER = "0",
	DYNAMIC_CONFIG__TEST_STRING = "local",
} = process.env;
/* eslint-enable @typescript-eslint/naming-convention */

const dynamicConfig = /** @type {const} */ {
	enableLandingPage: DYNAMIC_CONFIG__ENABLE_LANDING_PAGE === "true",
	enableLandingPageGeniusBanner: DYNAMIC_CONFIG__ENABLE_LANDING_PAGE_GENIUS_BANNER === "true",
	testNumber: Number(DYNAMIC_CONFIG__TEST_NUMBER),
	testString: DYNAMIC_CONFIG__TEST_STRING,
};

module.exports = dynamicConfig;
