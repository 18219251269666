export const TRANSPORT_TYPES = {
	"1": "standard",
	"2": "executive",
	"3": "executive-people-carrier",
	"4": "luxury",
	"5": "people-carrier",
	"6": "large-people-carrier",
	"7": "minibus",
	"8": "train",
	"9": "bus",
	"10": "shared-shuttle",
	"14": "electric-standard",
	"15": "electric-luxury",
};
