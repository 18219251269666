import { useMemo } from "react";
import { getOrchestrator } from "@bookingcom/web-page-orchestrator";
import type { Comments, Experiments, TaxiBookPageOrchestrator } from "../../orchestrator/TaxiBookPageOrchestrator";
import type { BroadcastableRateResult, RateParams, SelectedVehicle } from "../../types/taxi-search-results";
import type { Flight } from "../../types/flight-picker";
import type { ChildSeatValues } from "../../types/child-seats";

type EventHandler<Payload = unknown> = (handler: (payload: Payload) => void) => void;

export type TaxiBookPageOrchestratorClient = {
	continueToCheckout: TaxiBookPageOrchestrator["continueToCheckout"];
	getAffiliateBookingReference: TaxiBookPageOrchestrator["getAffiliateBookingReference"];
	getC360ResultsSkeleton: TaxiBookPageOrchestrator["getC360ResultsSkeleton"];
	getC360SearchSkeleton: TaxiBookPageOrchestrator["getC360SearchSkeleton"];
	getCheckoutParams: TaxiBookPageOrchestrator["getCheckoutParams"];
	getChildSeats: TaxiBookPageOrchestrator["getChildSeats"];
	getComments: TaxiBookPageOrchestrator["getComments"];
	getDiscount: TaxiBookPageOrchestrator["getDiscount"];
	getExperiments: TaxiBookPageOrchestrator["getExperiments"];
	getFlightInfo: TaxiBookPageOrchestrator["getFlightInfo"];
	getIsEncryptionInProgress: TaxiBookPageOrchestrator["getIsEncryptionInProgress"];
	getMarketingParams: TaxiBookPageOrchestrator["getMarketingParams"];
	getRateFailureReason: TaxiBookPageOrchestrator["getRateFailureReason"];
	getRateParams: TaxiBookPageOrchestrator["getRateParams"];
	getRateResult: TaxiBookPageOrchestrator["getRateResult"];
	getReturnRate: TaxiBookPageOrchestrator["getReturnRate"];
	getSelectedVehicle: TaxiBookPageOrchestrator["getSelectedVehicle"];
	onChildSeatsUpdated: EventHandler<ChildSeatValues>;
	onCommentsUpdated: EventHandler<Comments>;
	onExperimentsUpdated: EventHandler<Experiments>;
	onFlightInfoUpdated: EventHandler<Flight>;
	onPageRefreshTrigger: EventHandler<RateParams>;
	onRateFailed: EventHandler<string>;
	onRateParamsUpdated: EventHandler<RateParams>;
	onRateResultUpdated: EventHandler<BroadcastableRateResult>;
	onSelectedVehicleUpdated: EventHandler<SelectedVehicle>;
	sendC360Event: TaxiBookPageOrchestrator["sendC360Event"];
	sendGtmEvent: TaxiBookPageOrchestrator["sendGtmEvent"];
	trackGoal: TaxiBookPageOrchestrator["trackGoal"];
	trackStage: TaxiBookPageOrchestrator["trackStage"];
	triggerRateFailed: TaxiBookPageOrchestrator["triggerRateFailed"];
	updateAffiliateBookingReference: TaxiBookPageOrchestrator["updateAffiliateBookingReference"];
	updateC360ResultsSkeleton: TaxiBookPageOrchestrator["updateC360ResultsSkeleton"];
	updateC360SearchSkeleton: TaxiBookPageOrchestrator["updateC360SearchSkeleton"];
	updateChildSeats: TaxiBookPageOrchestrator["updateChildSeats"];
	updateComments: TaxiBookPageOrchestrator["updateComments"];
	updateDiscount: TaxiBookPageOrchestrator["updateDiscount"];
	updateExperiments: TaxiBookPageOrchestrator["updateExperiments"];
	updateFlightInfo: TaxiBookPageOrchestrator["updateFlightInfo"];
	updateMarketingParams: TaxiBookPageOrchestrator["updateMarketingParams"];
	updateRateParams: TaxiBookPageOrchestrator["updateRateParams"];
	updateRateResult: TaxiBookPageOrchestrator["updateRateResult"];
	updateReturnDateTime: TaxiBookPageOrchestrator["updateReturnDateTime"];
	updateSelectedVehicle: TaxiBookPageOrchestrator["updateSelectedVehicle"];
};

const useTaxiBookPageOrchestrator = (): TaxiBookPageOrchestratorClient => {
	const orchestrator = getOrchestrator();

	return useMemo<TaxiBookPageOrchestratorClient>(
		() => ({
			continueToCheckout: async (baseUrl) => orchestrator?.runCommand("CONTINUE_TO_CHECKOUT", baseUrl),
			getAffiliateBookingReference: () => orchestrator?.runQuery("AFFILIATE_BOOKING_REFERENCE"),
			getC360ResultsSkeleton: () => orchestrator?.runQuery("C360_RESULTS_SKELETON"),
			getC360SearchSkeleton: () => orchestrator?.runQuery("C360_SEARCH_SKELETON"),
			getCheckoutParams: () => orchestrator?.runQuery("CHECKOUT_PARAMS"),
			getChildSeats: () => orchestrator?.runQuery("CHILD_SEATS"),
			getComments: () => orchestrator?.runQuery("COMMENTS"),
			getDiscount: () => orchestrator?.runQuery("DISCOUNT"),
			getExperiments: async () => orchestrator.runQuery("EXPERIMENTS"),
			getFlightInfo: () => orchestrator?.runQuery("FLIGHT_INFO"),
			getIsEncryptionInProgress: () => orchestrator?.runQuery("IS_ENCRYPTING"),
			getMarketingParams: () => orchestrator.runQuery("MARKETING_PARAMS"),
			getRateFailureReason: async () => orchestrator?.runQuery("RATE_FAILURE_REASON"),
			getRateParams: () => orchestrator?.runQuery("RATE_PARAMS"),
			getRateResult: async () => orchestrator?.runQuery("RATE_RESULT"),
			getReturnRate: () => orchestrator?.runQuery("RETURN_RATE"),
			getSelectedVehicle: async () => orchestrator?.runQuery("SELECTED_VEHICLE"),
			onChildSeatsUpdated: (handler) => orchestrator?.on("CHILD_SEATS_UPDATED", handler),
			onCommentsUpdated: (handler) => orchestrator?.on("COMMENTS_UPDATED", handler),
			onExperimentsUpdated: async (handler) => orchestrator?.on("EXPERIMENTS_UPDATED", handler),
			onFlightInfoUpdated: (handler) => orchestrator?.on("FLIGHT_INFO_UPDATED", handler),
			onPageRefreshTrigger: (handler) => orchestrator.on("PAGE_REFRESH", handler),
			onRateFailed: (handler) => orchestrator?.on("RATE_FAILED", handler),
			onRateParamsUpdated: (handler) => orchestrator?.on("RATE_PARAMS_UPDATED", handler),
			onRateResultUpdated: (handler) => orchestrator?.on("RATE_RESULT_UPDATED", handler),
			onSelectedVehicleUpdated: (handler) => orchestrator?.on("SELECTED_VEHICLE_UPDATED", handler),
			sendC360Event: async (payload) => orchestrator?.runCommand("SEND_C360_EVENT", payload),
			sendGtmEvent: async (eventName) => orchestrator?.runCommand("SEND_GTM_EVENT", eventName),
			trackGoal: async (goalPayload) => orchestrator?.runCommand("TRACK_GOAL", goalPayload),
			trackStage: async (stagePayload) => orchestrator?.runCommand("TRACK_STAGE", stagePayload),
			triggerRateFailed: async (failureReason) => orchestrator?.runCommand("TRIGGER_RATE_FAILED", failureReason),
			updateAffiliateBookingReference: async (affiliateBookingReference) =>
				orchestrator?.runCommand("UPDATE_AFFILIATE_BOOKING_REFERENCE", affiliateBookingReference),
			updateC360ResultsSkeleton: (skeletonResultsPayload) =>
				orchestrator?.runCommand("UPDATE_C360_RESULTS_SKELETON", skeletonResultsPayload),
			updateC360SearchSkeleton: (skeletonSearchPayload) =>
				orchestrator?.runCommand("UPDATE_C360_SEARCH_SKELETON", skeletonSearchPayload),
			updateChildSeats: async (childSeats) => orchestrator?.runCommand("UPDATE_CHILD_SEATS", childSeats),
			updateComments: async (comments) => orchestrator?.runCommand("UPDATE_COMMENTS", comments),
			updateDiscount: async (discount) => orchestrator?.runCommand("UPDATE_DISCOUNT", discount),
			updateExperiments: async (experiments: Experiments) =>
				orchestrator.runCommand("UPDATE_EXPERIMENTS", experiments),
			updateFlightInfo: (flightInfo) => orchestrator?.runCommand("UPDATE_FLIGHT_INFO", flightInfo),
			updateMarketingParams: async (marketingParams) =>
				orchestrator.runCommand("UPDATE_MARKETING_PARAMS", marketingParams),
			updateRateParams: (rateParams) => orchestrator?.runCommand("UPDATE_RATE_PARAMS", rateParams),
			updateRateResult: async (rateResult) => orchestrator?.runCommand("UPDATE_RATE_RESULT", rateResult),
			updateReturnDateTime: async (returnDateTime) =>
				orchestrator?.runCommand("UPDATE_RETURN_DATE_TIME", returnDateTime),
			updateSelectedVehicle: async (selectedVehicle) =>
				orchestrator?.runCommand("UPDATE_SELECTED_VEHICLE", selectedVehicle),
		}),
		[orchestrator],
	);
};

export { useTaxiBookPageOrchestrator };
