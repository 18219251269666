/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable unicorn/no-array-reduce */
/* eslint-disable no-return-assign */
import React, { useEffect } from "react";
import { TaxiBookPageOrchestratorClient, useTaxiBookPageOrchestrator } from "../../hooks/useTaxiBookPageOrchestrator";
import { RateParams } from "../../types/taxi-search-results";
import { formatTime, formatYearMonthDay } from "../../utils/format-date";
import type { BookingDetailsExpectedParameters } from "../../pages/BookingDetailsPage/BookingDetailsPage.types";
import { DEFAULT_AFFILIATE_CODE, DEFAULT_CURRENCY, DEFAULT_LANGUAGE } from "../../constants/defaults";

type UrlGenerator = (
	rateParams: RateParams,
	orchestrator: TaxiBookPageOrchestratorClient,
) => Promise<BookingDetailsExpectedParameters>;

const generateUrl: UrlGenerator = async (rateParams, orchestrator) => {
	const {
		affiliate,
		currency,
		dropoff,
		dropoffType,
		dropoffEstablishment,
		language,
		passenger,
		pickup,
		pickupDateTime,
		pickupEstablishment,
		pickupType,
		preSelectedResultReference,
		returnDateTime,
		returnBannerClicked,
	} = rateParams;

	const outboundDate = new Date(pickupDateTime);

	const marketingParams = await orchestrator.getMarketingParams();

	const searchParams: BookingDetailsExpectedParameters = {
		affiliate: affiliate || DEFAULT_AFFILIATE_CODE,
		currency: currency || DEFAULT_CURRENCY,
		date: formatYearMonthDay(outboundDate),
		dropoff,
		dropoffEstablishment,
		dropoffType,
		lang: language || DEFAULT_LANGUAGE,
		passengers: passenger,
		pickup,
		pickupEstablishment,
		pickupType,
		preSelectedResultReference,
		time: formatTime(outboundDate),
		...marketingParams,
	};

	if (returnBannerClicked) {
		searchParams.returnBannerClicked = "true";
	}

	if (returnDateTime) {
		const returnDate = new Date(returnDateTime);

		searchParams.returnDate = formatYearMonthDay(returnDate);
		searchParams.returnTime = formatTime(returnDate);
	}

	return searchParams;
};

const generateFreeTaxiUrl: UrlGenerator = async (rateParams, orchestrator) => {
	const {
		passenger,
		pickup,
		pickupEstablishment,
		pickupType,
		pickupDateTime,
		preSelectedResultReference,
		language,
		dropoff,
		dropoffEstablishment,
		dropoffType,
	} = rateParams;

	const outboundDate = new Date(pickupDateTime);

	const marketingParams = await orchestrator.getMarketingParams();

	const searchParams: BookingDetailsExpectedParameters = {
		affiliate: "bookingfreetaxi",
		currency: DEFAULT_CURRENCY,
		date: formatYearMonthDay(outboundDate),
		dropoff,
		dropoffEstablishment,
		dropoffType,
		lang: language || DEFAULT_LANGUAGE,
		passengers: passenger,
		pickup,
		pickupEstablishment,
		pickupType,
		preSelectedResultReference,
		time: formatTime(outboundDate),
		...marketingParams,
	};

	return searchParams;
};

export interface SearchFormHandlerProps {
	isFreeTaxi?: boolean;
}

export const SearchFormHandler: React.FC<SearchFormHandlerProps> = ({ isFreeTaxi }) => {
	const orchestrator = useTaxiBookPageOrchestrator();

	useEffect(() => {
		const handlerBuilder =
			(urlGenerator: UrlGenerator) =>
			async (rateParams: RateParams): Promise<void> => {
				const searchParams = await urlGenerator(rateParams, orchestrator);
				const filteredParams = Object.entries(searchParams).reduce(
					(a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
					{},
				);
				window.location.href = `/search?${new URLSearchParams(filteredParams).toString()}`;
			};

		orchestrator.onPageRefreshTrigger(
			isFreeTaxi ? handlerBuilder(generateFreeTaxiUrl) : handlerBuilder(generateUrl),
		);
	}, [orchestrator, isFreeTaxi]);

	// We need to return something otherwise there won't be anything to render
	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <></>;
};
