/* eslint sort-keys: ["error", "asc", {"minKeys": 8}] */
import type { ETExperiments } from "@btransport/rides-et-middleware";

export const ET_EXPERIMENTS = {
	copyExperimentLingoContinue: {
		title: "pbt_search_continue_cta",
		stages: {
			dDot: 1,
			mDot: 2,
		},
		customGoals: {
			continueButtonClicked: 1,
		},
		enabled: true,
		manualTracking: true,
	},
	geniusBadges: {
		title: "pbt_coreex_web_genius_badges_merchandising",
		stages: {
			dDot: 1,
			mDot: 2,
			standardVehicleVisilble: 3,
			peopleCarrierVehicleVisilble: 4,
		},
		customGoals: {},
		enabled: true,
		manualTracking: true,
	},
	howItWorksComponent: {
		title: "pbt_coreex_prebook_how_it_works_component_ddot",
		stages: {
			dDot: 0,
		},
		customGoals: {
			progressionToCheckout: 1,
		},
		enabled: true,
		manualTracking: true,
	},
	lessThan48HourMandatoryFlightNumber: {
		title: "pbt_rbex_core_experience_less_than_48_hours_mandatory_flight_number",
		stages: {
			paymentShown: 1,
		},
		customGoals: {},
		enabled: true,
		manualTracking: true,
	},
	mfsTranslations: {
		title: "pbt_coreex_translations_mfs_vs_gt",
		stages: {
			dDot: 1,
			mDot: 2,
		},
		customGoals: {},
		enabled: true,
	},
	preferLocationApi: {
		title: "pbt_coreex_prefer_location_api",
		stages: {
			dDot: 1,
			mDot: 2,
		},
		customGoals: {},
		enabled: true,
		manualTracking: true,
	},
	returns20: {
		title: "pbt_coreex_apps_cross_platform_genius_returns_20",
		stages: {
			base: 0,
			main: 1,
			outboundSearch: 2,
			roundTripSearch: 3,
			coreWeb: 6,
		},
		customGoals: {
			returnBannerClicked: 2,
		},
		manualTracking: true,
		enabled: true,
	},
	returnsInCore: {
		title: "pbt_coreex_web_returns_in_search_results_v2",
		stages: {
			returnBannerSearchResults: 1,
		},
		customGoals: {
			returnBannerClicked: 1,
			addReturnClicked: 2,
		},
		manualTracking: true,
		enabled: true,
	},
	returnsV3: {
		title: "pbt_landing_and_search_web_returns_v3",
		stages: {
			mDot: 1,
			dDot: 2,
			emptyReturnResult: 3,
		},
		customGoals: {
			clicksOnReturnCTA: 1,
			clicksOnCheckPrices: 2,
			clicksOnAddReturnJourney: 3,
			addReturnSuccessful: 4,
			addingReturnUsingSearchToggle: 5,
		},
		enabled: true,
		manualTracking: true,
	},
	searchFormReturns: {
		title: "pbt_coreex_web_returns_in_search_form",
		stages: {
			dDot: 1,
			mDot: 2,
		},
		customGoals: {},
		enabled: true,
	},
	useNewAutocomplete: {
		title: "pbt_searchform_new_autocomplete",
		stages: {
			dDot: 1,
			mDot: 2,
		},
		customGoals: {},
		enabled: true,
		manualTracking: true,
	},
	validateRequestBodies: {
		title: "pbt_coreex_web_validate_request_bodies",
		stages: {
			dDot: 1,
			mDot: 2,
		},
		customGoals: {
			encryptedComment: 1,
			editedPassengerDetails: 2,
			bookedTaxi: 3,
		},
		enabled: true,
	},
} as const satisfies ETExperiments;

export const ET_PERMANENT_GOALS = {};

export const ET_VARIANTS = {
	baseVariant: 0,
	variant1: 1,
} as const;
