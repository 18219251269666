/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/naming-convention */
import { v4 as uuidv4 } from "uuid";
import type { BroadcastableRateResult, RateParams } from "../types/taxi-search-results";
import type { C360TravelProductResults, C360TravelProductSearch, TravelProductResults } from "../utils/c360/c360.types";
import { TRANSPORT_TYPES } from "../constants/transport-types";

const getCarTypeIdFromCarType = (carType: string): number => {
	return parseInt(Object.keys(TRANSPORT_TYPES).find((key) => TRANSPORT_TYPES[key] === carType));
};

const mapTravelProductResults = (rateResult: BroadcastableRateResult, discountType: string): TravelProductResults[] => {
	if (!rateResult.isReturn) {
		return rateResult.outbound?.results?.map((result) => {
			const isDiscounted = result.originalPrice && result.priceDiscountPercentage !== 0;
			let discounted_price;
			let discounted_price_currency;
			let original_price = result.price.toString();
			let discounted_type;

			if (isDiscounted) {
				discounted_price = result.price.toString();
				discounted_price_currency = result.currency;
				original_price = result.originalPrice.toString();
				discounted_type = discountType;
			}

			return {
				supplier_name: result.supplierName,
				travel_product: {
					displayed_price: {
						discounted_price,
						discounted_price_currency,
						discounted_type,
						original_price,
						original_price_currency: result.currency,
					},
				},
				travel_products: {
					travel_product_id: getCarTypeIdFromCarType(result.typeForResultReference),
				},
			};
		});
	}

	const filtered = rateResult?.outbound?.results?.filter((outboundResult) =>
		rateResult?.inbound?.results?.find((inboundResult) => {
			return inboundResult.resultReference === outboundResult.resultReference;
		}),
	);

	return filtered?.map((outboundWithReturn) => {
		const matchingInboundResult = rateResult?.inbound?.results?.find(
			(inboundResult) => inboundResult.resultReference === outboundWithReturn.resultReference,
		);

		const isDiscounted = outboundWithReturn.originalPrice && outboundWithReturn.priceDiscountPercentage !== 0;

		let discounted_price;
		let discounted_price_currency;
		let original_price = (outboundWithReturn.price + matchingInboundResult.price).toString();
		let discounted_type;

		if (isDiscounted) {
			discounted_price = (outboundWithReturn.price + matchingInboundResult.price).toString();
			discounted_price_currency = outboundWithReturn.currency;
			original_price = (outboundWithReturn.originalPrice + matchingInboundResult.originalPrice).toString();
			discounted_type = discountType;
		}

		return {
			supplier_name: outboundWithReturn.supplierName,
			travel_product: {
				displayed_price: {
					discounted_price,
					discounted_price_currency,
					discounted_type,
					original_price,
					original_price_currency: outboundWithReturn.currency,
				},
			},
			travel_products: {
				travel_product_id: getCarTypeIdFromCarType(outboundWithReturn.typeForResultReference),
			},
		};
	});
};

export const hydrateTravelProductResults = (
	skeletonEvent: C360TravelProductResults,
	rateResult: BroadcastableRateResult,
): C360TravelProductResults => {
	const discountType =
		skeletonEvent?.content?.travel_product_results?.[0]?.travel_product?.displayed_price?.discounted_type;

	return {
		...skeletonEvent,
		content: {
			...skeletonEvent?.content,
			travel_product_results: mapTravelProductResults(rateResult, discountType),
			trip_end_date: rateResult?.inbound?.requestedPickupDateTime,
			trip_start_date: rateResult?.outbound?.requestedPickupDateTime,
		},
	};
};

export const hydrateTravelProductSearch = (
	skeletonEvent: C360TravelProductSearch,
	rateParams: RateParams,
): C360TravelProductSearch => {
	const payloadFromParams = {
		destination_location: rateParams.dropoffEstablishment,
		destination_location_id: rateParams.dropoff,
		destination_type: rateParams.dropoffType,
		group_size: parseInt(rateParams.passenger),
		origin_location: rateParams.pickupEstablishment,
		origin_location_id: rateParams.pickup,
		origin_type: rateParams.pickupType,
		passenger_adult_count: parseInt(rateParams.passenger),
		// passenger_children_age
		// passenger_children_count
		trip_end_date: rateParams.returnDateTime,
		trip_start_date: rateParams.pickupDateTime,
	};

	const sanitisedPayloadFromParams = Object.fromEntries(
		Object.entries(payloadFromParams).filter(([, value]) => !!value),
	);

	return {
		...skeletonEvent,
		content: {
			...skeletonEvent?.content,
			...sanitisedPayloadFromParams,
			search_timestamp: Date.now().toString(),
			search_id: uuidv4(),
		},
	};
};
