import React from "react";
import {
	RemoteComponentContext,
	ComponentDefinition,
	IRemoteComponentContext,
	useRemoteComponent,
} from "@bookingcom/remote-component";
import { Box, Container } from "@bookingcom/bui-react";

export interface GeniusBannerProps {
	remoteComponentContext?: IRemoteComponentContext;
	remoteComponentDefinitions?: Record<string, ComponentDefinition>;
}

interface GeniusBannerInnerProps {
	landingPageGeniusBanner?: ComponentDefinition;
}

export const GeniusBannerInner: React.FC<GeniusBannerInnerProps> = ({ landingPageGeniusBanner }) => {
	const { isLoading, error, Component: GeniusBannerRemoteComponent } = useRemoteComponent(landingPageGeniusBanner);

	return (
		<div>
			{landingPageGeniusBanner && !isLoading && !error && (
				<Container>
					<Box
						mixin={{
							padding: 0,
							paddingBlockEnd: 14,
						}}
					>
						<GeniusBannerRemoteComponent />
					</Box>
				</Container>
			)}
		</div>
	);
};

export const GeniusBanner: React.FC<GeniusBannerProps> = (props) => {
	return (
		<RemoteComponentContext.Provider value={props.remoteComponentContext}>
			<GeniusBannerInner landingPageGeniusBanner={props.remoteComponentDefinitions?.landingPageGeniusBanner} />
		</RemoteComponentContext.Provider>
	);
};
