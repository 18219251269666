export const replaceNullAndEmptyFieldsWithUndefined = <T>(obj: T): T => {
	if (obj === null || obj === "") {
		return undefined;
	}

	if (typeof obj === "object") {
		if (Array.isArray(obj)) {
			return obj.map((item) => replaceNullAndEmptyFieldsWithUndefined(item)) as T;
		}

		const newObj = {} as T;
		// eslint-disable-next-line no-restricted-syntax
		for (const key in obj) {
			// eslint-disable-next-line no-prototype-builtins
			if (obj.hasOwnProperty(key)) {
				newObj[key] = replaceNullAndEmptyFieldsWithUndefined(obj[key]);
			}
		}

		return newObj;
	}

	return obj;
};
